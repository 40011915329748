import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginModal from "../components/sales-pages/loginModal"
import Hero from "../components/sales-pages/hero"
import VSL from "../components/sales-pages/vsl"
import Bio from "../components/mentorBio"
import FAQ from "../components/faq"
import CTAButton from "../components/sales-pages/ctaButton"
import LectureList from "../components/lectureList"
import LiveChat from "../components/sales-pages/liveChat"
import { useStaticQuery, graphql } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import "../styles/education.scss"
import "../styles/content.scss"
import publicLectureInfo from "../lectures/cfw101"

export default ({ pageContext: { course } }) => {
  const { title, subtitle, slug } = course
  const [toggleLogin, setToggleLogin] = React.useState(false)

  const { isLoggedIn } = useAuth()
  const images = useStaticQuery(graphql`
    query {
      hero: file(
        absolutePath: { regex: "/education/cfw101/cfw101-hero.jpg/" }
      ) {
        childImageSharp {
          fixed {
            src
          }
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/education/cfw101.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`${title}: ${subtitle}`}
        description={subtitle}
        image={images.logo.childImageSharp.fixed.src}
      />

      {toggleLogin && (
        <LoginModal
          isLoggedIn={isLoggedIn}
          course={course}
          slug={slug}
          setToggleLogin={setToggleLogin}
        />
      )}
      <Hero
        image={images.hero.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
        button={
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        }
      />

      <div className="course-sales-content">
        <div className="row">
          <div className="column50">
            <VSL vidLink="428258798" noAutoPlay />
          </div>
          <div className="column50">
            <p className="video-explainer">
              Knowing the basics of web development can save you thousands of
              dollars. Knowing a little bit more can <b>MAKE</b> you a full-time
              income. Learn the basics and get rolling into a brighter future by
              coding for the web!
              <CTAButton
                isLoggedIn={isLoggedIn}
                slug={slug}
                setToggleLogin={setToggleLogin}
              />
            </p>
          </div>
        </div>
        <div className="row">
          <p>
            This class is for complete beginners in web development. Never
            written a line of code before? <strong>Great</strong>! Hop on in. By
            the end of this class you'll have made an{" "}
            <strong>
              <em>entire website</em>
            </strong>{" "}
            from scratch!
          </p>

          <strong>Along the way you'll learn:</strong>
          <div style={{ marginTop: "2rem" }}>
            <p>
              <strong>
                <u>Setting Up Your Coding Environment</u>
              </strong>
            </p>
            <ul className="fancy-checks">
              <li>
                Understand where coding has been and{" "}
                <strong>where it&rsquo;s going</strong> (and why it&rsquo;s the{" "}
                <em>easiest</em> time to start learning)
              </li>
              <li>
                Get the <b>#1 code editor</b> on the planet to make your coding
                experience a breeze.
              </li>
              <li>
                Download the most powerful addons and extensions for your code
                editor to ensure maximum performance.
              </li>
            </ul>
            <p>
              <strong>
                <u>HTML (HyperText Markup Language)</u>
              </strong>
            </p>
            <ul className="fancy-checks">
              <li>
                The particular language and &lsquo;<em>syntax</em>&rsquo; of
                HTML.
              </li>
              <li>How to structure and understand the layout of web pages.</li>
              <li>
                Bring in any <em>fonts, icons, pictures</em>, etc from all
                around the web to use on your own site.
              </li>
            </ul>
            <p>
              <strong>
                <u>CSS (Cascading Style Sheet):</u>
              </strong>
            </p>
            <ul className="fancy-checks">
              <li>Make your site your own with colors, layouts, and styles.</li>
              <li>Utilizing fonts and styles made by others on your site.</li>
              <li>
                Realize the most beautiful site using simple to follow
                guidelines for styling pages.
              </li>
            </ul>
            <p>
              <strong>
                <u>Hosting On The Internet:</u>
              </strong>
            </p>
            <ul className="fancy-checks">
              <li>
                Use one of the fastest servers for your new site &ndash;{" "}
                <strong>
                  <em>for FREE.</em>
                </strong>
              </li>
              <li>
                Easily and quickly make changes and updates to your site
                automatically.
              </li>
              <li>
                Access a <strong>massive network</strong> of free templates,
                tools, and projects you can use on your own site.
              </li>
            </ul>
            <p>
              <strong>
                <em>
                  <u>Bonus Extras:</u>
                </em>
              </strong>
            </p>
            <ul className="fancy-checks">
              <li>
                <strong>HTML Cheatsheet</strong> so you can always lookup
                anything confusing.
              </li>
              <li>
                <strong>CSS Cheatsheet</strong> for making a{" "}
                <em>masterpiece</em> of every single page.
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
        <br />
        <br />
        <hr />
        <div className="row">
          <Bio />
        </div>
        <br />
        <hr />
        <br />
        <h2>📚&nbsp;Course Curriculum</h2>
        <LectureList
          lectureInfo={publicLectureInfo}
          chooseLecture={null}
          chosenLecture={{}}
        />{" "}
        <hr />
        <br />
        <FAQ />
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
